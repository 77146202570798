.rtl_mode {
    .rtl_me_2{
            margin-right: 0.5rem!important;
    }
    .rtl_ms_2{
            margin-left: 0.5rem!important;
    }
    .mail-inbox .mail-left {
       
        border-left: 1px solid var(--border-color);
        border-right: none;
       
    }
    #social-form .input-group .btn, #social-form .input-group .input-group-text , #social-form .form-control {
        border-radius: 6px;
    }
    .ps12 {
        padding-right: 12px !important;
    }
    .home-section .testimonial .row .tabs li:nth-child(2)::before {
        right: 320px;
        top: 50%;
    }

    .home-section .testimonial .row .tabs li:nth-child(2)::after {
        bottom: 101px;
        border-radius: 50%;
        right: 400px;
    }

    .other-option.d-flex {
        gap: 20px;
    }

    .home-section .banner-section .banner-content .banner-form .find-btn i {
        left: -70px;
        top: 2px;
        position: relative;
    }
    .companyDetails .c-profile-social-buttons ul {
        padding-left: 0;
        margin-bottom: 0;
        text-align: right;
        margin: auto;
        list-style: none;
    }
    .people-list .chat-list li .name .new-msg-count {
        position: absolute;
        left: 0 !important;
        right: auto !important;
        top: 5px;
    }
// ======================
.checkmark {
    position: absolute;
    top: 0;
    right: 0;
    left: auto;
    height: 26px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
}
// =====================



     .ar_text{
        text-align: start !important;
     }
     .ar_contain{
        display: inline-block;
        padding-right: 35px;
     }
    .other-option.d-flex {
        gap: 12px !important;
    }

    .footer-widget.footer-info {
        text-align: end !important;
    }

    .footer-widget.pl-60 {
        text-align: end !important;
    }

    .signin-section .form-control.is-valid,
    .signin-section .was-validated .form-control:valid {
        border-top-left-radius: 20px !important;
        border-bottom-left-radius: 20px !important;
        border: none !important;
    }

    .input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
    }

    .signin-section .animate-right {
        position: relative;
        animation: animateright 0s !important;
        left: -100% !important;
    }

    .login-section .animate-left {
        position: relative;
        animation: asnimateright 2s !important;
        display: none;

    }

    .login-section.sign-up-mode .animate-left {
        position: relative;
        animation: animateright 2s !important;
        display: block;

    }

    .signin-section .login-section.sign-up-mode .right-panel {
        pointer-events: all;
        position: absolute !important;
    }

    .searched-jobs {
        .searched-bar {
            padding: 0 14px 0 4px;
        }

        .job-info {
            padding: 0 26px 0px 0;
        }

    }

    .password-eye {
        position: absolute;
        left: 17px;
        right: auto;
        top: 5px;
        z-index: 99;
    }

    .eye-set {
        position: absolute;
        top: 0;
        left: 0px !important;
        right: auto !important;
        margin-left: 14px;
    }

    i.fa.fa-home {
        padding: 0 18px 0 0px !important;
    }

    #wrapper form#navbar-search {
        border: 2px solid #ffff;
        color: #fff;
        border-radius: 7px;
    }

    a.btn.btn-sm.btn-outline-info.me-2 {
        margin-left: 7px;
    }

    .navbar.navbar-fixed-top {
        margin-right: 280px;
    }

    .signin-section .form-control.is-valid,
    .signin-section .was-validated .form-control:valid {
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
        border: none;
    }

    input#password_confirmation {
        border-top-left-radius: 20px !important;
        border-bottom-left-radius: 20px !important;
    }

    // input#name {
    //     border-top-left-radius: 20px !important;
    //     border-bottom-left-radius: 20px !important;
    // }

    button.btn-toggle-fullwidth {
        margin-left: 30px;
    }

    .contact-section .list-inline-item:not(:last-child) {
        margin-right: 0px !important;
        margin-bottom: 0px !important;
    }

    ul.list-unstyled.social-icon li {
        margin-left: 8px !important;
    }

    .bx {
        line-height: 0;
    }

    .contact-section .float-left {
        float: right !important;
        margin: 0 0px 0 20px;
    }

    .rtl_mode.layout-fullwidth .navbar.navbar-fixed-top {
        margin-right: 0px;
    }

    i.fa.fa-building {
        margin-left: 0.5rem;
    }

    i.fa.fa-map {
        margin-left: 0.5rem;
    }

    .job-section .search-button {
        border-radius: 8px 0px 0px 8px;
    }

    .job-section .type-container {
        display: flex;
        align-items: center;
        color: var(--subtitle-color);
        font-size: 13px;
        justify-content: space-between;
    }

    .job-section .job-style+label:before {
        content: "";
        margin-left: 10px;

    }

    .companyDetails .key-highlights .key-highlights-body .common-card {
        gap: 8px;
    }

    .ms-auto {
        margin-left: 20px !important;
    }

    .close-button-sign-up {
        margin-left: auto;
    }

    .home-section .banner-section .banner-content .banner-form .form-group label {
        // left: 220px !important;
        // position: relative;
        width: 100%;
        text-align: right !important;
    }

    .css-1jqq78o-placeholder {
        text-align: right;
    }

    .footer-area .footer-widget h3 {
        color: #ffffff;
        position: relative;
        margin-bottom: 40px;
        font-weight: 600;
        direction: ltr;
    }

    .footer-area .footer-widget h3::before {
        position: absolute !important;
        content: '';
        background: #ffffff;
        height: 2px;
        width: 50px;
        right: 0px !important;
        bottom: -15px;
        left: auto !important;
    }

    .footer-area .footer-widget li {
        list-style: none !important;
        color: #fff;
        text-align: start;
    }

    .job-section .card__pricing {
        right: auto;
        top: -1rem;
        padding: 10px 0;
        left: 0;
    }

    .css-13cymwt-control {
        text-align: right;
    }

    // .home-section .testimonial .row .tabs li.active::before {
    //  display: none;
    // }
    // .home-section .testimonial .row .tabs li.active::after {
    //     display: none;
    // }
    .footer-area .bx {
        color: #ffff;
        margin-left: 7px;
    }

    .job-section .card__pricing::before {
        /* width: 14px; */
        /* height: 16px; */
        background-color: transparent !important;
        top: 0px;
        right: -16px;
        clip-path: none !important;
        border-top: 8px solid transparent !important;
        border-left: 8px solid #58719f !important;
        border-bottom: 8px solid #58719f !important;
        border-right: 8px solid transparent;
    }

    .home-section .banner-section .banner-content .banner-form .form-group {
        margin-bottom: 0;
        line-height: 1;
        text-align: start !important;
    }

    .spend-main {
        border-top-right-radius: 20px !important;
        border-bottom-right-radius: 20px !important;
        border-left: 1px solid #dee2e6;
        border-top: 1px solid #dee2e6;
        border-bottom: 1px solid #dee2e6;
        display: flex;
        align-items: center;
    }

    i.fa.fa-key {
        margin-left: 10px;
    }

    i.fa.fa-cog.me-2 {
        margin-left: 10px;
    }

    i.fa.fa-globe.me-2 {
        margin-left: 10px;
    }

    i.fa.fa-user.me-2 {
        margin-left: 10px;
    }
    
    .main-profile .profile-image .p-image {
        position: absolute;
        top: 67px;
        right: 102px !important;
        color: #666666;
        color: #666666;
        transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }

    .job-arbic label.form-check-label {
        margin-right: 50px;
    }

    .customSelect {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        height: 42px;
    }

    .rdw-dropdown-carettoopen {
        top: 35%;
        left: 10%;
        right: 0 !important;
    }

    .start-job {
        text-align: start !important;
    }

    .end-job {
        text-align: end !important;
    }
    .job-details-section .job-sidebar ul.text-start {
        text-align: right !important;
    }
    // .react-select{
    //     height: 20px;
    //     overflow-y: 20px;
    // }

    .iti--allow-dropdown input, .iti--allow-dropdown input[type=text], .iti--allow-dropdown input[type=tel], .iti--separate-dial-code input, .iti--separate-dial-code input[type=text], .iti--separate-dial-code input[type=tel] {
        padding-right: 110px;
    }
    .iti--allow-dropdown .iti__flag-container, .iti--separate-dial-code .iti__flag-container {
        width: 100px;
        right: 0;
    }

   #viewJobList .modal-header .btn-close {
        margin: 0px !important;
    }
    .dataTables_wrapper .dataTables_length, .dataTables_wrapper .dataTables_filter {
        font-size: 0;
        display: flex;
    }
    .rdw-dropdown-selectedtext {
        justify-content: end !important;
    }
    .public-DraftStyleDefault-ltr {
        text-align: end !important;
    }
    #chat-job-details-view-modal .modal-header .btn-close {
        padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
        margin: 0;
    }
    #videoModal .modal-header .btn-close {
      margin:0px !important;
    }
    #resumeModal_0 .modal-header .btn-close {
        margin:0px !important;
      }

    .chat .chat-history .other-message:after {
        margin-right: -31px !important;
        right: 99% !important;
    }
    .key-highlights-title{
        text-align: start !important;
    }
    .key-highlights-body .name {
      text-align: start;
    }
    .key-highlights-body .label {
       text-align: start;
    }
    .modal-header .btn-close {
        margin:0px !important;
      }
      .rtl-box-size{
        margin: 18px 0px !important;
      }
      .navbar-nav li {
        text-align: justify !important;
    }
    
    .breadcrumb-item.active::before {
        margin-left: 8px;
    }
    .breadcrumb-item a i, .breadcrumb-item  img{
        margin: 0 !important;
        margin-left: 6px !important;
        margin-right: 6px !important;
    }
  
    .ar_text_btn{
        text-align: end !important;
    }
    @media screen and (max-width: 991px){
.mail-inbox .mail-left {
    width: 200px;
    padding: 0px 0px 0px 15px; 
}
.mail-inbox .mail-right {
    width: calc(100% - 200px);
    position: relative;
}
.dataTables_wrapper .dataTables_length {
    float: right;
}
}
@media screen and (max-width: 767px){
.mail_wrapper .mail-inbox .email_toggle_btn, .chat_wrapper .chat-app .toggle_btn  {
    position: absolute;
    top: -66px;
    right: auto;
    left: 0;
}
.mail-inbox .mail-right {
    width:100%;
    position: relative;
}
.mail-inbox .mail-left {
    width:100%;
    padding: 0px 0px 0px 15px; 
    border: none;
}
}
}