.spinner {
    margin: 0 auto;
    width: 70px;
    text-align: center;
    
    > div {
      width: 18px;
      height: 18px;
      background-color: #333;
      border-radius: 100%;
      display: inline-block;
      animation: sk-bouncedelay 1.2s infinite ease-in-out both;
    }
    .bounce1 {
      animation-delay: -0.32s;
    }
    .bounce2 {
      animation-delay: -0.16s;
    }
  }
  
  @-webkit-keyframes sk-bouncedelay {0%, 80%, 100% {
      transform: scale(0);
    }
  
    40% {
      transform: scale(1);
    }
  }
  
  
  @keyframes sk-bouncedelay {0%, 80%, 100% {
      transform: scale(0);
    }
  
    40% {
      transform: scale(1);
    }
  }

