$color_1: var(--font-color);
$color_2: var(--primary-color);
$color_3: #0c85e0;
$color_4: var(--bg-hc-color);
$color_5: #ff812d;
$color_6: var(--text-muted);
$background-color_1: var(--font-xlight);
$background-color_2: var(--white-color);
$border-color_1: var(--border-color);
$border-color_2: var(--bg-hc-color);

:root {
	--dark-color: #323A45;
	--heading-color: #a87e7e;
	--font-color: #555555;
	--font-white: #ffffff;
	--font-light: #888888;
	--font-xlight: #f0f0f0;
	--font-ccc: #cccccc;
	--text-muted: #9A9A9A;
	--border-color: #e2e2e2;
	--card-color: #ffffff;
	--white-color: #ffffff;
	--body-color: #fcfcfc;
	--box-shadow: #c7c1c1;
	--box-shadow-light: #ececec;
	--font-hc-color: #888888;
	--bg-hc-color: #323A45;
}

[data-theme="dark"] {
	--dark-color: #ffffff;
	--heading-color: #c0c0c0;
	--font-color: #c7c7c7;
	--font-white: #ffffff;
	--font-light: #888888;
	--font-xlight: #333638;
	--font-ccc: #cccccc;
	--text-muted: #80858a;
	--border-color: #3b3f42;
	--card-color: #26282a;
	--white-color: #000000;
	--body-color: #222426;
	--box-shadow: #585858;
	--box-shadow-light: #323536;
	--font-hc-color: #888888;
	--bg-hc-color: #ffffff;
}

[data-theme="high-contrast"] {
	--dark-color: #ffffff;
	--heading-color: #ffffff;
	--font-color: #ffffff;
	--font-white: #ffffff;
	--font-light: #000000;
	--font-xlight: #ffffff;
	--font-ccc: #111111;
	--text-muted: #ffffff;
	--border-color: #ffffff;
	--card-color: #000000;
	--white-color: #000000;
	--body-color: #000000;
	--box-shadow: #333333;
	--box-shadow-light: #333333;
	--font-hc-color: #ffffff;
	--bg-hc-color: #000000;
}

[class="theme-purple"] {
	--primary-color: #7954AD;
	--primary-color2: #7B54B1;
	--primary-color3: #A37ADB;
	--secondary-color: #E76886;
	--secondary-color2: rgb(223, 126, 148);
	--link-color: #59C4BC;
	--primary-gradient: linear-gradient(45deg, #7B54B1, #E76886);
}

[class="theme-blue"] {
	--primary-color: #2929b9;
	--primary-color2: #4343D3;
	--primary-color3: #6C6CFF;
	--secondary-color: #F99006;
	--secondary-color2: #F92F47;
	--link-color: #87B70A;
	--primary-gradient: linear-gradient(45deg, #2929b9, #87B70A);
}

[class="theme-cyan"] {
	--primary-color: #59C4BC;
	--primary-color2: #33BAB0;
	--primary-color3: #8BCEC9;
	--secondary-color: #637AAE;
	--secondary-color2: #00ACEE;
	--link-color: #E76886;
	--primary-gradient: linear-gradient(45deg, #59C4BC, #637AAE);
}

[class="theme-green"] {
	--primary-color: #38C172;
	--primary-color2: #249D57;
	--primary-color3: #187741;
	--secondary-color: #0957C3;
	--secondary-color2: #5589D2;
	--link-color: #FED500;
	--primary-gradient: linear-gradient(45deg, #38C172, #249D57);
}

[class="theme-orange"] {
	--primary-color: #FFA901;
	--primary-color2: #FF7F00;
	--primary-color3: #FBC200;
	--secondary-color: #600489;
	--secondary-color2: #9439BC;
	--link-color: #38C172;
	--primary-gradient: linear-gradient(45deg, #FFA901, #600489);
}

[class="theme-blush"] {
	--primary-color: #FF6A4A;
	--primary-color2: #F5856D;
	--primary-color3: #FBC200;
	--secondary-color: #87B70A;
	--secondary-color2: #AAD736;
	--link-color: #056CDB;
	--primary-gradient: linear-gradient(45deg, #FF6A4A, #AAD736);
}

[class="theme-red"] {
	--primary-color: #F40000;
	--primary-color2: #F92C2C;
	--primary-color3: #F14F4F;
	--secondary-color: #030886;
	--secondary-color2: #252BCF;
	--link-color: #C7E203;
	--primary-gradient: linear-gradient(45deg, #F40000, #252BCF);
}
.btn-darke.mail_toggle_btn.focus, .btn-dark.email_toggle_btn:focus {
   
    box-shadow:none !important;
}
.numbering_box{
	border: 1px solid #59c4bc;
    height: 28px;
    padding: 1px 6px;
    border-radius: 5px;
    font-size: 15px;
}
.mail-inbox {
	display: flex;
	flex-direction: row;

	.mail-left {
		width: 280px;
		padding: 15px;
		border-right: 1px solid var(--border-color);
		display: block;
		height: 77vh;

		.mail-side {
			.nav {
				flex-direction: column;

				li {
					margin-bottom: 5px;
					line-height: 40px;

					a {
						color: $color_1;
						display: flex;
						align-items: center;

						i {
							width: 35px;
							-webkit-transition: font-size 0.2s;
							-moz-transition: font-size 0.2s;
							transition: font-size 0.2s;
						}

						.badge {
							margin-left: auto;
							margin-right: 0;
						}
					}

					&:hover {
						a {
							color: $color_2;
						}
					}
				}

				li.active {
					a {
						color: $color_2;
					}
				}
			}

			h3 {
				font-weight: 500;
				font-size: 15px;
				margin-bottom: 15px;
				margin-top: 30px;
				line-height: 20px;
			}
		}
	}

	.mail-right {
		width: calc(100% - 280px);
		position: relative;

		.header {
			padding: 15px;
		}

		h2 {
			line-height: 35px;
		}

		.mail-action {
			padding: 15px;

			.fancy-checkbox {
				label {
					margin-bottom: 0;
				}

				input[type="checkbox"]+span {
					&:before {
						bottom: 0;
					}
				}
			}

			.btn {
				border-color: $border-color_1;
				padding: 5px 15px;
			}

			.pagination-email {
				p {
					line-height: 30px;
					margin-bottom: 0;
				}
			}
		}

		.mail-list {
			padding: 15px 0;

			ul {
				li {
					padding: 5px 15px;
					margin-top: 10px;
					border: 1px solid var(--border-color);
					width: 100%;
					position: relative;

					&:last-child {
						border-bottom: 1px solid var(--border-color);
					}

					.hover-action {
						position: absolute;
						opacity: 0;
						top: 0;
						right: 0;
						padding: 23px 16px 23px;
						background: var(--white-color);
						transition: all 0.5s ease-in-out;
					}

					&:hover {
						.hover-action {
							opacity: 1;
						}

						.mail-detail-expand {
							color: $color_3;
						}
					}

					.mail-detail-left {
						float: left;
						max-width: 60px;
						min-width: 60px;
						width: 60px;
						position: relative;

						.mail-star {
							position: absolute;
							right: 13px;
							top: 0;
							color: $color_1;
						}

						.mail-star.active {
							color: $color_5;
						}
					}

					.mail-detail-right {
						float: left;
						position: relative;
						padding-right: 70px;
						width: calc(100% - 70px);

						span.time {
							position: absolute;
							top: 0;
							right: 0;
							color: $color_4;
						}

						h6 {
							color: $color_6;
							width: 100%;
							display: flex;
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden;

							a {
								color: $color_1;
							}
						}

						p {
							color: $color_6;
							width: 100%;
							display: flex;
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden;
							margin-bottom: 0;
						}
					}
				}

				li.unread {
					background-color: $background-color_1;

					.hover-action {
						background-color: $background-color_1;
					}

					.fancy-checkbox {
						input[type="checkbox"]+span {
							&:before {
								border-color: $border-color_2;
							}
						}
					}

					.mail-detail-left {
						.mail-star {
							color: $color_4;
						}
					}

					.mail-detail-right {
						h6 {
							a {
								color: $color_4;
							}
						}

						p {
							color: $color_4;
						}
					}
				}
			}
		}

		.mail-detail-full {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background: var(--white-color);

			.mail-action {
				padding: 15px;
			}

			.detail-header {
				padding: 20px 15px 0;
				border-top: 1px solid var(--border-color);
				border-bottom: 1px solid var(--border-color);

				.float-left {
					img {
						max-width: 63px;
						border-radius: 5px;
					}
				}
			}

			.media-body {
				p {
					a {
						color: $color_1;
					}
				}
			}

			.mail-cnt {
				padding: 20px 15px;
			}
		}
	}
}
.email_toggle_btn {
	display: none;
}




.mail-compose.m-b-20 a {
	background-color: #061322;
	color: #fff;
	border-radius: 5px;

	&:hover {
		background-color: #59c4bc !important;
		border-color: #59c4bc;
	}
}

@media screen and (max-width: 991px) {
	.mail-inbox {
		.mail-left {
			width: 190px;
			padding: 0px 15px 0px 0px;
			border-right: 1px solid var(--border-color);
			display: block;
		}
		.mail-right {
			width: calc(100% - 190px);
			position: relative;
		}
	}
}

@media screen and (max-width: 767px) {
	// .mobile-left {
	// 	display: block;
	// 	padding: 15px 15px 0;
	// 	position: absolute;
	// 	z-index: 9999;
	// 	right: 0;
	// }
	.mail_wrapper .mail-inbox .email_toggle_btn {
		position: absolute;
		top: -66px;
		right: 0;
	}
	.mail_wrapper  .card.custom_card.cardNew{overflow: visible !important;}
	.email_toggle_btn {
		display: block;
		width: 100%;
		max-width: 50px;
		margin-left: 16px;
	}
	
	.mail-inbox {
		
		display: flex;
    flex-direction: column;
    min-height: calc(100vh - 175px);
    height: auto;
			.mail-left {
				width: 100%;
				padding: 0px;
				border-right: none;
				display: block;
			}

		.mail-right {
			width: 100%;

			.header {
				flex-direction: column;

				.ml-auto {
					margin-left: 0 !important;
					margin-top: 15px;
				}
			}
		}
	}
	.manage_email_toggle .mail-left {
		transform: none;
		transition: all 0.3s ease-in-out;
		height: 0;
		overflow: hidden;
	}
	.email_toggle_left_show .mail-left {
		transform: none;
		transition: all 0.3s ease-in-out;
		height: auto;
	}
	.btn-group {
		margin-bottom: 5px;
	}

	.mail-detail-full {
		a.mail-back {
			top: -60px;
		}
	}

	.media-body {
		p {
			span {
				display: none;
			}

			small {
				display: none;
			}
		}
	}
	
}

.modalSelect {

	select,
	input,
	textarea {
		width: 50%;
		border-radius: 6px;
		background: transparent;
		padding-left: 10px;
		padding-right: 10px !important;
		border: 1px solid #e2e2e2 !important;
	}

	select {
		height: 40px !important;
		margin-bottom: 25px;
	}

	input {
		height: 40px !important;
		margin-bottom: 10px !important;
	}

	label {
		font-size: 20px !important;
		color: #000000 !important;
	}
}


.invalid {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: .875em;
    color: var(--bs-form-invalid-color);
}

.mail-detail-left {
	position: absolute;
	top: 15px;
}
.message-content {
	height: calc(100% - 80px); /* Adjust the height based on your needs */
	overflow-y: auto; /* Enable vertical scrolling if the content exceeds the card's height */
}

.extra-bold{
	font-weight: 800;
	font-size: 15px;;
}
