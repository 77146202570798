.page-loader-wrapper {
    background: #061322;
}

.sidebar {
    top: 0;
    background: #061322;
    box-shadow: inset 0px 0px 1px 0 var(--box-shadow-light) !important;
    width: 281px;
}

.navbar-fixed-top {
    background: #061322;
    position: fixed;
    z-index: 990 !important;
}

#main-content {
    margin-top: 80px;
    background-color: #fff !important;
}

.cursor_pointer{
    cursor: pointer;
}

.disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  #zmmtg-root {
    display: none;
  }
  
#wrapper {
   i.fa.fa-bars {
        color: #ffff;
    }

    .navbar-fixed-top .navbar-brand a {
        font-weight: 700;
        color: #ffff;
        font-size: 22px;
        
    }

    .dropdown {
        color: #ffff;

    }
    
    .sidebar-nav .metismenu>li .active>a {
        font-weight: 700;
        color: #84d4a6;
    }

    .sidebar-nav .metismenu .has-arrow::after {
        color: #ffff;
    }

    .sidebar-nav .metismenu ul a {
        color: #ffff;
    }

    .breadcrumb-item.active {
        color: #061322;
    }

    .navbar-nav .icon-menu i {
        color: #fff;
    }

    .navbar-nav .icon-menu .notification-dot {
        background-color: #ffff;
    }

    .page_action {
        display: flex;
        gap: 10px;
    }

    .btn.btn-primary {
        background-color: #061322;
        border-color: #061322;
    }

    .btn.btn-secondary {
        background-color: #061322;
        border-color: #061322;
    }

    form#navbar-search {
        border: 2px solid #ffff;
        color: #fff;
        border-radius: 12px;
    }

    .sidebar-nav .metismenu>li i {
        color: #ffff;
    }
    
    .sidebar-nav .metismenu a {
        color: #ffff;
    }

    .search-form .form-control {
        background: #fff;
    }

    .user-account .dropdown .dropdown-menu {
        box-shadow: none;
        background: #ffff;
    }

    .user-account .dropdown .dropdown-menu a {
        padding: 10px;
        font-size: 14px;
        color: #061322;
    }

    i.fa.fa-search {
        color: #061322;
        font-size: 15px;
    }
    button.btn.btn-default {
        top: 7px;
        left: 8px;
    }
    form#navbar-search input[type="text"]::placeholder {
        position: relative;
        left: 20px;
    }
}
.dropdown-menu>.active>a, .dropdown-menu>.active>a:hover, .dropdown-menu>.active>a:focus{
    background-color: #061322 !important;
    color: #fff !important;
}
.sidebar.open .btn-toggle-offcanvas {
    color: white;
}

.dropdown-menu>.active>a, .dropdown-menu>.active>a:hover, .dropdown-menu>.active>a:focus{
    background-color: #061322 !important;
    color: #fff !important;
}
.sidebar-nav .metismenu>li{
    border-bottom: 1px solid #80808021;
} 
.sidebar-nav .log_out{
    border-bottom: none !important;
}
.mail-compose.m-b-20 a:hover {
    color: #FFF;
     background-color:#061322;
     border-color: #061322;
   }
   .form-control:focus {
    border-color: #061322 !important;
}
#wrapper .btn.btn-primary:hover {
    background-color: #59C4BC !important;
    border-color:#59C4BC !important;
}
button.btn-toggle-fullwidth {
    margin-right: 30px !important;
}
.iti {
    position: relative;
    display: inline-block;
    width: 100%;
}

button.btn.btn-primary.w-120px {
    margin-top: 35px;
  }
.react-datepicker-wrapper{
    width: 100%;
}

.file-upload__label {
 padding: 5px 15px 5px 15px !important;
}
input#birth_date {
    height: 42px;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
}
.btn-primary.focus, .btn-primary:focus {
    color: #fff;
    box-shadow: 0 0 0 0.2rem #59c4bc;
}
.eye-set{
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 14px;
}

.dropbtn {
   
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #fff;
    min-width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  .dropdown-content a:hover{
    background: red;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}
  
  .dropdown-content a:hover {background-color: #fff;}
  
  .dropdown:hover .dropdown-content {display: block;}
  
//   .dropdown:hover .dropbtn {background-color: #3e8e41;}
.user-account .user-photo {
    height: 50px;
}
span.number {
    font-size: 12px;
    font-weight: 800;
    position: absolute;
    top: 6px;
    left: 0;
    right: -27px;
    bottom: 0px;
}
ul.notification-main {
    list-style: none !important;
    text-decoration: none;
}
li.notification:hover {
    background: #061322;
    color: #fff;
    list-style: none !important;
    text-decoration: none;
}
li.notification {
    list-style: none !important;
    text-decoration: none;
    color:#061322;
}
.start-job {
    text-align: start !important;
}
.end-job {
    text-align: end !important;
}
.rdw-dropdown-selectedtext {
    justify-content: start !important;
}

.bi-x-circle-fill {
    color: gray !important;
    z-index: 9999 !important;
}
.public-DraftStyleDefault-ltr {
    text-align: start !important;
}
.react-select-height{
    min-height: 151px !important;
}
.action-width{
    width: 150px !important;
}
.last-delete{
 opacity: 0.3 !important;
}

@media (min-width:767.8px) and (max-width:991.8px) {
    .eye-set{
        position: absolute;
        top: 0;
        right: 0;
        margin-right: 0px;
    }
}