$color_1: var(#9b9ba5);
$color_2: var(#2f2f33);
$color_3: var(#081c3c);
$color_4: #94949f;
$color_5: #081c3c;
$color_6: var(#fff);
$color_7: var(#83838e);
$color_8: #fff;
$color_9: red;
$color_10: #ffff;
$color_11: #dedede;
$color_12: var(#fc5757);
$color_13: var(#5052d5);
$color_14: #FFFFFF;
$font-family_1: var("Poppins", sans-serif);
$font-family_2: monospace;
$background-color_1: var(#e5ecef);
$background-color_2: var(#fafafb);
$background-color_3: var(#fff);
$background-color_4: var(#081c3c);
$background-color_5: transparent;
$background-color_6: #d4e5ff;
$background-color_7: var(#f0f0f0);
$background-color_8: var(#e1ebfb);
$background-color_9: #575ad8;
$background-color_10: var(#9b9ba5);
$background-color_11: #fff;
$background-color_12: #DDD;
$border-color_1: var(#081c3c);
$border-color_2: #fff;

.job-details-section {


    .job-card {
        padding: 20px;
        border-radius: 15px;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);

        .job-info h3 {
            margin-bottom: 10px;
        }

        .job-info ul li {
            display: inline-block;
            font-size: 15px;
            padding-right: 10px;
            color: #001935;
        }

        .bx {
            font-family: boxicons !important;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            line-height: 1;
            text-rendering: auto;
            display: inline-block;
            text-transform: none;
        }
    }

    .details-text {
        padding: 20px;
        border-radius: 15px;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);

        li {
            display: block;
            margin-right: 8px;
            margin-top: 10px;
        }
    }

    .job-sidebar {
        text-align: center;
        padding: 20px;
        border-radius: 15px;
        -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
        margin-bottom: 30px;

        ul {
            padding-left: 0;
            margin-bottom: 0;
            text-align: left;
            margin: auto;
            text-align: center;
            list-style: none;

            li {
                display: inline-block;
                margin-right: 8px;
                margin-top: 10px;

                a {
                    border: 1px dashed #ddd;
                    padding: 5px 10px;
                    color: #001935;
                    display: inline-block;
                    font-size: 16px;
                }
            }

        }
    }

    .theme-btn {
        margin-top: 13px;
    }

    .details-text li {
        color: #707070 !important;  
    }

    .banner-section-contact .banner-content h1 {
        // font-size: 44px !important;
        font-weight: 600 !important;
        color: #ffffff;
        max-width: 100%;
        margin: 0 auto 40px;
        text-transform: capitalize;
      }
}

.jobFilterSelect .css-13cymwt-control {
    border: none !important;
    border-color: transparent !important;
    box-shadow: none !important;
}

.job-info .d-flex{
    align-items: center !important;
}

.resume_selected {
    border-radius: 10px;
    background-color: antiquewhite;
    padding: 25px;
}

.resume_padding {
    padding: 25px;
}

div#applyJob {
    top: 100px;
}
input[type=range][orient=vertical] {
    // appearance: slider-vertical;
    writing-mode: vertical-lr;
    width: 10px;
    height: 290px;
    padding: 0 30px;
}

@media screen and (max-width:992px) {
    .banner-section-contact .banner-content h1 {
        font-size: 44px !important;
       
      }
}