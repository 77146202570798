.payment-failed-content {
    text-align: center;
}

.payment-failed-content h4 {
    font-weight: 800;
    font-size: 30px;
    color: #ff1112; margin-bottom: 0; text-transform: uppercase;
}

.payment-failed-left-img {
    max-width: 400px;
    margin: 0 auto 20px;
}

.payment-failed-right-img {
    max-width: 500px;
    margin: 0 auto;
}

.payment-failed-section img {
    width: 100%;
}

@media  (max-width:767.98px) {
    .payment-failed-left-img {
        max-width: 260px;
    }
    
    .payment-failed-content h4 {
        font-size: 24px;
    }
}


.payment-success-btn { display: inline-block; padding: 10px 20px; color: #fff !important; font-size: 16px; font-weight: 600; letter-spacing: 2px; border-radius: 10px; text-decoration: none; background-color: #010c29; }
.payment-success-btn:hover { color: #fff; background: #2443ac; }

