	/*
  	Flaticon icon font: Flaticon
  	Creation date: 07/05/2021 14:26
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff2") format("woff2"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
  font-style: normal;

}

.flaticon-accounting:before { content: "\f100"; }
.flaticon-graduation-cap:before { content: "\f101"; }
.flaticon-wrench-and-screwdriver-in-cross:before { content: "\f102"; }
.flaticon-consultation:before { content: "\f103"; }
.flaticon-heart:before { content: "\f104"; }
.flaticon-computer:before { content: "\f105"; }
.flaticon-worker:before { content: "\f106"; }
.flaticon-auction:before { content: "\f107"; }
.flaticon-website:before { content: "\f108"; }
.flaticon-recruitment:before { content: "\f109"; }
.flaticon-login:before { content: "\f10a"; }
.flaticon-group:before { content: "\f10b"; }
.flaticon-discussion:before { content: "\f10c"; }
.flaticon-research:before { content: "\f10d"; }
.flaticon-user:before { content: "\f10e"; }
.flaticon-left-quotes-sign:before { content: "\f10f"; }
.flaticon-resume:before { content: "\f110"; }
.flaticon-employee:before { content: "\f111"; }
.flaticon-portfolio:before { content: "\f112"; }
.flaticon-results:before { content: "\f113"; }
.flaticon-computer-1:before { content: "\f114"; }